<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Notifikasi</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" xl="6" lg="6" md="6" sm="12">
        <h3>Notifikasi Email</h3>
        <v-container>
          <v-row>
            <v-col align-self="center"> Newsletter </v-col>
            <v-spacer></v-spacer>
            <v-col cols="2">
              <v-switch color="primary" hide-details></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <h3>Notifikasi Website</h3>
        <v-container>
          <v-row>
            <v-col align-self="center"> Menunggu Pembayaran </v-col>
            <v-spacer></v-spacer>
            <v-col cols="2">
              <v-checkbox color="primary" hide-details></v-checkbox>
            </v-col>
          </v-row>

          <v-divider> </v-divider>

          <v-row>
            <v-col align-self="center"> Pengingat Tiket Upcoming </v-col>
            <v-spacer></v-spacer>
            <v-col cols="2">
              <v-checkbox color="primary" hide-details></v-checkbox>
            </v-col>
          </v-row>

          <v-divider> </v-divider>

          <v-row>
            <v-col align-self="center"> Pengingat Tiket Disimpan </v-col>
            <v-spacer></v-spacer>
            <v-col cols="2">
              <v-checkbox color="primary" hide-details></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
</style>