<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Profil Saya</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" xl="6" lg="6" md="6" sm="12">
        <v-form ref="profile">
          <v-card class="bg-color">
            <v-card-text>
              <div class="text-h6 font-weight-bold">Nama</div>
              <div>
                <v-text-field
                  @keypress="filterName(event)"
                  v-model="user.full_name"
                  variant="outlined"
                  :rules="[(v) => !!v || 'Nama tidak boleh kosong']"
                  placeholder="Nama User"
                >
                </v-text-field>
              </div>
            </v-card-text>
            <v-card-text>
              <div class="text-h6 font-weight-bold">Email</div>
              <div>
                <v-text-field
                  v-model="user.user_email"
                  disabled
                  variant="outlined"
                  placeholder="Email"
                >
                </v-text-field>
              </div>
            </v-card-text>
            <v-card-text>
              <div class="text-h6 font-weight-bold">Nomor HP</div>
              <div>
                <v-text-field
                  v-model="user.user_phone"
                  disabled
                  variant="outlined"
                  placeholder="Email"
                >
                </v-text-field>
              </div>
            </v-card-text>
            <v-card-text>
              <div class="text-h6 font-weight-bold">Password</div>
              <div>
                <v-text-field
                  type="password"
                  v-model="user.password"
                  variant="outlined"
                  placeholder="Password"
                  readonly
                >
                  <template v-slot:append-inner>
                    <v-btn
                      class="ma-2 text-none"
                      :to="{ name: 'reset-password' }"
                      variant="tonal"
                      color="primary"
                    >
                      Ubah Password
                    </v-btn>
                  </template>
                </v-text-field>
              </div>
            </v-card-text>

            <v-card-actions>
              <v-btn block class="bg-primary" @click="edit"> Simpan </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>

  <v-snackbar snackbar color="white" v-model="snackbar.status" rounded="pill">
    <div class="text-black">
      {{ snackbar.text }}
    </div>

    <template v-slot:actions>
      <v-btn
        color="pink"
        rounded="pill"
        variant="text"
        @click="snackbar.status = false"
      >
        Tutup
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import Cookies from "js-cookie";
export default {
  data() {
    return {
      user: {
        full_name: "",
        password: "",
      },
      snackbar: {
        status: false,
        text: "",
      },
    };
  },
  computed: {
    getUser() {
      return this.$store.state.user;
    },
  },
  watch: {
    getUser(value) {
      const fullName = value.user_firstname + " " + value.user_lastname;
      this.user = value;
      this.user.full_name = fullName;
    },
  },
  async created() {
    const userCookies = Cookies.get("user_data");
    if (userCookies) {
      const data = JSON.parse(userCookies);
      const response = await this.axios.get("user/" + data.user_id);
      this.$store.dispatch("loadUser", response.data.response);
    }
  },
  methods: {
    filterName: function (evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[a-zA-Z][a-zA-Z\s]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async edit() {
      const { valid } = await this.$refs.profile.validate();

      if (valid) {
        const fullName = this.user.full_name.split(" ");
        const firstName = fullName[0];
        const lastName = fullName.slice(1).join(" ");

        this.user.user_firstname = firstName;
        this.user.user_lastname = lastName;

        this.axios
          .put("user/info/update", {
            user_firstname: firstName,
            user_lastname: lastName || " ",
          })
          .then(() => {
            this.$store.dispatch("loadUser", this.user);
            this.snackbar.text = "Profile berhasil diupdate";
            this.snackbar.status = true;
          })
          .catch(() => {
            this.snackbar.text = "Profile gagal diupdate";
            this.snackbar.status = true;
          });
      }
    },
  },
};
</script>

<style scoped>
:deep(.v-field__append-inner) {
  padding: 0;
}
</style>