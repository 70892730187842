<template>
  <v-navigation-drawer
    v-if="!show"
    order="1"
    class="bg-color"
    permanent
    absolute
    location="left"
  >
    <v-list active-class="text-dark-on-primary bg-neutral-90" nav>
      <v-list-item>
        <h1>Akun</h1>
      </v-list-item>

      <v-list-item
        prepend-icon="mdi-home-variant"
        title="Profil Saya"
        value="profile"
        :to="{ name: 'profile' }"
      ></v-list-item>

      <v-list-item
        prepend-icon="mdi-ticket-confirmation"
        title="Pesanan Saya"
        value="my-order"
        :to="{ name: 'my-order' }"
      ></v-list-item>

      <v-list-item
        prepend-icon="mdi-bookmark-box-multiple"
        title="Disimpan"
        value="bookmark"
        :to="{ name: 'bookmark' }"
      ></v-list-item>

      <v-list-group value="settings">
        <template v-slot:activator="{ props }">
          <v-list-item
            prepend-icon="mdi-cog"
            v-bind="props"
            title="Pengaturan"
            value="setting"
          ></v-list-item>
        </template>

        <v-list-item
          title="Ubah Kata Sandi"
          :to="{ name: 'reset-password' }"
          value="reset-password"
        ></v-list-item>
        <!-- <v-list-item
          title="Preferensi"
          value="preference-settings"
          disabled
          :to="{ name: 'user-pref' }"
        ></v-list-item>
        <v-list-item
          title="Notifikasi"
          disabled
          value="notification-settings"
          :to="{ name: 'notification-setting' }"
        ></v-list-item> -->
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { useDisplay } from "vuetify/lib/framework.mjs";
export default {
  computed: {
    show() {
      const display = useDisplay();

      return display.xs.value;
    },
  },
};
</script>

<style></style>