<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Ubah Kata Sandi</h1>
      </v-col>
    </v-row>

    <v-form ref="newPassword">
      <v-row>
        <v-col cols="12" xl="6" lg="6" md="6" sm="12">
          <v-card class="bg-color">
            <v-card-text>
              <v-text-field
                v-model="input.old_password"
                variant="outlined"
                :rules="[(v) => !!v || 'Password lama harus diisi']"
                :type="visible.old_password ? 'text' : 'password'"
                label="Password Lama"
                @keypress="error.old_password = ''"
                @blur="checkPassword(input.old_password)"
                :error="input.old_password && !isOldPasswordValid"
                :messages="['Tulis password yang saat ini sedang kamu gunakan']"
              >
                <template #message="{ message }">
                  <div class="mb-2">
                    {{ message }}
                  </div>
                </template>

                <template #append-inner>
                  <v-icon
                    v-if="input.old_password && isOldPasswordValid"
                    icon="mdi-check-circle"
                    color="primary"
                    class="me-1"
                    :style="{ opacity: 1 }"
                  >
                  </v-icon>
                  <v-icon
                    v-else-if="input.old_password && !isOldPasswordValid"
                    icon="mdi-alert-circle"
                    color="error"
                    class="me-1"
                    :style="{ opacity: 1 }"
                  >
                  </v-icon>
                  <v-icon
                    @click="visible.old_password = !visible.old_password"
                    :icon="visible.old_password ? 'mdi-eye-off' : 'mdi-eye'"
                  >
                  </v-icon>
                </template>
              </v-text-field>
            </v-card-text>

            <v-card-text>
              <v-text-field
                v-model="input.new_password"
                variant="outlined"
                :type="visible.new_password ? 'text' : 'password'"
                label="Password Baru"
                :error="input.new_password && !isNewPasswordValid"
                :messages="[
                  'Password minimal 8 digit gabungan huruf besar, huruf kecil, angka dan simbol',
                  'Password baru tidak boleh sama dengan password lama',
                ]"
              >
                <template #message="{ message }">
                  <div class="mb-2">
                    <v-icon>mdi-circle-small</v-icon>
                    {{ message }}
                  </div>
                </template>

                <template #append-inner>
                  <v-icon
                    v-if="input.new_password && isNewPasswordValid"
                    icon="mdi-check-circle"
                    color="primary"
                    class="me-1"
                    :style="{ opacity: 1 }"
                  >
                  </v-icon>
                  <v-icon
                    v-else-if="input.new_password && !isNewPasswordValid"
                    icon="mdi-alert-circle"
                    color="error"
                    class="me-1"
                    :style="{ opacity: 1 }"
                  >
                  </v-icon>
                  <v-icon
                    @click="visible.new_password = !visible.new_password"
                    :icon="visible.new_password ? 'mdi-eye-off' : 'mdi-eye'"
                  >
                  </v-icon>
                </template>
              </v-text-field>
            </v-card-text>
            <v-card-text>
              <v-text-field
                v-model="input.retype_password"
                variant="outlined"
                :type="visible.retype_password ? 'text' : 'password'"
                :rules="rules.retype_password"
                label="Konfirmasi Password"
                hint="Ketik ulang passwordmu"
                persistent-hint
              >
                <template #append-inner>
                  <v-icon
                    @click="visible.retype_password = !visible.retype_password"
                    :icon="visible.retype_password ? 'mdi-eye-off' : 'mdi-eye'"
                  >
                  </v-icon>
                </template>
              </v-text-field>
            </v-card-text>

            <v-card-actions>
              <v-btn
                :loading="loadingSave"
                class="bg-primary"
                @click="save"
                block
                >Simpan</v-btn
              >
            </v-card-actions>
            <v-card-actions>
              <v-btn class="text-primary" @click="back" block>Kembali</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-container>

  <v-snackbar snackbar color="white" v-model="snackbar.show" rounded="pill">
    <div class="text-black">
      {{ snackbar.text }}
    </div>
    <template v-slot:actions>
      <v-btn color="red" variant="text" @click="snackbar.show = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import isStrongPassword from "validator/lib/isStrongPassword";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      password: null,
      userID: null,
      error: {
        old_password: "",
        new_password: "",
      },
      visible: {
        new_password: false,
        old_password: false,
        retype_password: false,
      },
      snackbar: {
        show: false,
        text: "",
      },
      rules: {
        password: [
          (v) => !!v || "Password wajib diisi",
          (v) => (v && v.length >= 8) || "Password kurang dari 8 karakter",
          (v) =>
            isStrongPassword(v) ||
            "Password harus terdiri atas huruf kapital, huruf kecil, simbol, dan angka",
        ],
        retype_password: [
          (v) => !!v || "Konfirmasi password perlu diisi",
          (v) =>
            v === this.input.new_password || "Konfirmasi password-mu salah",
        ],
      },
      input: {
        old_password: null,
        new_password: null,
        retype_password: null,
      },
      loadingSave: false,
      oldPasswordValid: false,
    };
  },
  computed: {
    isOldPasswordValid() {
      return this.oldPasswordValid;
    },
    isNewPasswordValid() {
      return (
        this.input.new_password?.length >= 8 &&
        this.input.new_password != this.input.old_password &&
        isStrongPassword(this.input.new_password)
      );
    },
  },
  async created() {
    const userCookies = Cookies.get("user_data");
    if (userCookies) {
      const user = JSON.parse(userCookies);
      this.userID = user.user_id;
    }
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    async save() {
      this.loadingSave = true;

      if (!this.oldPasswordValid) {
        this.error.old_password = "Password lama anda tidak sesuai";
        this.loadingSave = false;
        return;
      } else if (this.input.old_password == this.input.new_password) {
        this.error.new_password =
          "Password baru tidak boleh sama dengan password lama";
        this.loadingSave = false;
        return;
      }

      const { valid } = await this.$refs.newPassword.validate();

      if (!valid || !this.isNewPasswordValid || !this.isOldPasswordValid) {
        this.loadingSave = false;
        return;
      }

      const body = {
        user_id: this.userID,
        password: this.input.new_password,
        old_password: this.input.old_password,
      };

      this.axios
        .put("/user/password/update", body)
        .then(() => {
          this.snackbar.text = "Password berhasil diubah";
          this.snackbar.show = true;
          this.password = this.input.new_password;
          this.input = {
            password: null,
            new_password: null,
            retype_password: null,
          };
        })
        .catch(() => {
          this.snackbar.text = "Password gagal diubah";
          this.snackbar.show = true;
        })
        .finally(() => {
          this.loadingSave = false;
        });
    },
    async checkPassword(password) {
      const response = await this.axios.post(
        `user/${this.userID}/check-password`,
        {
          old_password: password,
        }
      );
      this.oldPasswordValid = response.data.response;
    },
  },

  watch: {
    "input.old_password": {
      handler(value) {
        this.checkPassword(value);
      },
    },
  },
};
</script>

<style></style>
