<template>
  <v-container>
    <v-row>
      <v-col cols="12" xxl="12" xl="12" lg="12" md="12" sm="12">
        <h1>Disimpan</h1>
        <div>
          Halaman ini menampilkan tiket-tiket yang sudah kamu simpan sebelumnya.
          Yuk, beli sebelum kehabisan!
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-show="false" cols="12" xxl="4" xl="4" lg="4" md="4" sm="4">
        <div class="d-flex">
          <v-btn variant="outlined" class="text-none me-2">Tiket Aktif</v-btn>
          <v-btn variant="outlined" class="text-none mx-2">Kadaluarsa</v-btn>
        </div>
      </v-col>

      <v-spacer></v-spacer>

      <v-col cols="12" xxl="5" xl="5" lg="5" md="5" sm="5">
        <div class="d-flex">
          <v-select
            v-model="sortBy"
            density="compact"
            variant="outlined"
            label="Urut Berdasarkan"
            :items="filter.sort.data"
            item-title="title"
            item-value="value_name"
            hide-details
            class="me-2"
          ></v-select>
          <!-- <v-btn color="primary" variant="text" class="text-none">Koleksi Tiket</v-btn> -->
        </div>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <v-col v-if="loading" cols="12" xxl="9" xl="9" lg="9" md="9" sm="9">
        <template v-for="index in 5" :key="index">
          <v-card class="my-5" variant="outlined">
            <v-card-title class="d-flex">
              <v-icon color="secondary-60"> mdi-ticket-confirmation </v-icon>
              <div class="me-auto">
                <v-skeleton-loader width="200" type="text"></v-skeleton-loader>
              </div>
              <div class="mx-2 text-grey">
                <v-icon> mdi-dots-vertical </v-icon>
              </div>
            </v-card-title>

            <v-divider :thickness="3"></v-divider>

            <v-card-text>
              <div class="d-flex justify-space-between my-3">
                <v-skeleton-loader
                  type="image"
                  id="producttype-image"
                ></v-skeleton-loader>

                <v-card-text class="pt-0">
                  <div class="text-body-1 font-weight-bold">
                    <v-skeleton-loader
                      width="200"
                      type="text"
                    ></v-skeleton-loader>
                  </div>
                  <div class="text-body-2 font-weight-bold text-secondary-60">
                    <v-skeleton-loader
                      width="100"
                      type="text"
                    ></v-skeleton-loader>
                  </div>
                </v-card-text>

                <v-card-actions class="align-self-end">
                  <v-spacer></v-spacer>
                  <v-btn
                    :disabled="loading"
                    color="primary"
                    variant="flat"
                    class="text-none"
                    >Beli</v-btn
                  >
                </v-card-actions>
              </div>
            </v-card-text>
          </v-card>
        </template>
      </v-col>

      <template v-else-if="bookmarks.length == 0 && !loading">
        <error
          :width="500"
          type="no_product"
          title="Kamu Tidak Memiliki Tiket Yang Tersimpan"
          text="Silahkan cari tiket dan simpan tiket yang kamu suka."
        />
      </template>

      <template v-else>
        <v-col cols="12" xxl="9" xl="9" lg="9" md="9" sm="9">
          <template v-for="(bookmark, index) in bookmarks" :key="index">
            <v-card
              v-for="productType in bookmark.product?.product_types"
              :key="productType"
              class="my-5"
              variant="outlined"
            >
              <v-card-title class="d-flex">
                <v-icon color="secondary-60"> mdi-ticket-confirmation </v-icon>
                <div class="me-auto">
                  <div v-if="bookmark.product.product_category.parent_category">
                    {{
                      `${bookmark.product.product_category.parent_category?.productcategory_name} |
                                        ${bookmark.product.product_category.productcategory_name}`
                    }}
                  </div>
                  <div v-else>
                    {{ bookmark.product.product_category.productcategory_name }}
                  </div>
                </div>
                <div class="mx-2 text-grey">
                  <v-btn variant="text" class="text-grey" size="small" icon>
                    <v-icon> mdi-dots-vertical </v-icon>

                    <v-menu activator="parent">
                      <v-list nav>
                        <v-btn
                          block
                          variant="text"
                          class="justify-start my-2 text-none"
                        >
                          <template #prepend>
                            <v-icon>mdi-share-variant</v-icon>
                          </template>

                          Bagikan

                          <v-menu
                            location="left"
                            offset="20"
                            activator="parent"
                          >
                            <v-list>
                              <v-list-item
                                @click="share('wa', bookmark.product)"
                                >WhatsApp
                              </v-list-item>
                              <v-list-item
                                @click="share('telegram', bookmark.product)"
                                >Telegram
                              </v-list-item>
                              <v-list-item
                                @click="share('facebook', bookmark.product)"
                                >Facebook
                              </v-list-item>
                              <v-list-item
                                @click="share('twitter', bookmark.product)"
                                >Twitter
                              </v-list-item>
                              <v-list-item
                                @click="copyUrlStore(bookmark.product)"
                                >Salin Tautan
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-btn>
                        <!-- 
                        <v-btn block variant="text" class="justify-start my-2 text-none">
                          <template #prepend>
                            <v-icon>mdi-bookmark-box-multiple</v-icon>
                          </template>
                          Simpan Ke Koleksi
                        </v-btn> -->

                        <v-btn
                          block
                          variant="text"
                          class="justify-start my-2 text-none"
                          @click="
                            removeBookmark(
                              productType.producttype_id,
                              productType
                            )
                          "
                        >
                          <template #prepend>
                            <v-icon>mdi-trash-can</v-icon>
                          </template>
                          Hapus
                        </v-btn>
                      </v-list>
                    </v-menu>
                  </v-btn>
                </div>
              </v-card-title>

              <v-divider :thickness="3"></v-divider>

              <v-card-text>
                <div class="d-flex justify-space-between my-3">
                  <v-img
                    width="25%"
                    height="25%"
                    max-width="25%"
                    max-height="25%"
                    :src="
                      bookmark.product?.product_details[0]
                        .productdetail_image_url
                    "
                    :lazy-src="
                      bookmark.product?.product_details[0]
                        .productdetail_image_url
                    "
                  >
                  </v-img>

                  <v-card-text>
                    <div class="text-body-1 font-weight-bold">
                      {{ productType.producttype_name }}
                    </div>
                    <div class="text-body-2 font-weight-bold text-secondary-60">
                      {{ numberToRupiah(productType.producttype_price) }}
                    </div>
                  </v-card-text>

                  <v-card-actions class="align-self-end">
                    <v-spacer></v-spacer>
                    <v-btn
                      :to="`/detail/${bookmark.product.product_id}`"
                      color="primary"
                      variant="flat"
                      class="text-none"
                      >Beli</v-btn
                    >
                  </v-card-actions>
                </div>
              </v-card-text>
            </v-card>
          </template>
        </v-col>

        <v-col cols="12" xxl="9" xl="9" lg="9" md="9" sm="9">
          <v-pagination
            :disabled="loading"
            v-model="this.page"
            :length="lastPage"
          ></v-pagination>
        </v-col>
      </template>
    </v-row>
  </v-container>

  <v-snackbar snackbar color="white" v-model="snackbar.status" rounded="pill">
    <div class="text-black">
      {{ snackbar.text }}
    </div>

    <template v-slot:actions>
      <v-btn
        color="pink"
        rounded="pill"
        variant="text"
        @click="snackbar.status = false"
      >
        Tutup
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import Cookies from "js-cookie";
import { numberToRupiah } from "@/utils/numberFormatter";
import Error from "@/components/error/ErrorComponent.vue";

const sortBySelection = [
  {
    title: "Hampir Selesai",
    value: "closest_date",
    order: "desc",
    value_name: "closest_date",
  },
];

export default {
  components: { Error },
  data() {
    return {
      bookmarks: [],
      page: 1,
      itemLength: 100,
      itemsPerPage: 10,
      loading: true,
      sortBy: sortBySelection[0],
      filter: {
        sort: {
          data: sortBySelection,
        },
      },
      userID: null,
      snackbar: {
        status: false,
        text: "",
      },
    };
  },

  computed: {
    lastPage() {
      return Math.ceil(this.itemLength / this.itemsPerPage);
    },
  },

  methods: {
    share(platform, product) {
      const url = window.location.origin + "/detail/" + product.product_id;
      const text = `Temukan ${product.product_name} - ${
        product.product_types[0].producttype_name
      } seharga ${numberToRupiah(
        product.product_types[0].producttype_price
      )}. Dapatkan sekarang juga di e-tix!`;
      let path = "";

      switch (platform) {
        case "wa": {
          path = `https://api.whatsapp.com/send?text=${text} ${url}`;
          break;
        }
        case "telegram": {
          path = `https://t.me/share/url?url=${url}&text=${text}`;
          break;
        }
        case "twitter": {
          path = `https://twitter.com/intent/tweet?url=${url}&text=${text}`;
          break;
        }
        case "facebook": {
          path = `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${text}`;
          break;
        }
      }

      window.open(path, "_blank");
    },
    fallbackCopyTextToClipboard(text) {
      var textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";
      textArea.style.display = "hidden";

      document.getElementById("dialog-share").appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand("copy");
        this.showSnackbar(`Link store berhasil di salin ke clipboard`);
      } catch (err) {
        this.showSnackbar(`Gagal menyalin data`);
      }

      document.getElementById("dialog-share").removeChild(textArea);
    },
    async copyUrlStore(product) {
      const url = window.location.origin + "/detail/" + product.product_id;
      if (navigator.clipboard) {
        await navigator.clipboard
          .writeText(url)
          .then(() => {
            this.showSnackbar(`Link store berhasil di salin ke clipboard`);
          })
          .catch(() => {
            this.showSnackbar(`Gagal menyalin data`);
          });
      } else {
        {
          this.fallbackCopyTextToClipboard(url);
        }
      }
    },
    removeBookmark(producttype_id, productType) {
      const userId = this.$store.state.user.user_id;
      const bookmark = productType.bookmarked_by.find(
        (bookmark) => bookmark.user_id === parseInt(userId)
      );
      const bookmark_id = bookmark.bookmark_id;
      this.axios
        .delete("product/bookmark/delete", {
          data: {
            user_id: userId,
            producttype_id: producttype_id,
            bookmark_id: bookmark_id,
          },
        })
        .then(() => {
          this.showSnackbar("Tiket berhasil dihapus");
          this.getBookmark();
        })
        .catch(() => {
          this.showSnackbar("Tiket gagal dihapus!");
        });
    },
    showSnackbar(text) {
      this.snackbar.status = true;
      this.snackbar.text = text;
    },
    numberToRupiah(number) {
      return numberToRupiah(number);
    },

    async getBookmark() {
      this.loading = true;

      await this.axios
        .get("product/bookmark/get", {
          params: {
            page: this.page,
            order: this.sortBy.order,
            pageSize: this.itemsPerPage,
            sortby: this.sortBy.value,
            user_id: this.userID,
          },
        })
        .then((response) => {
          this.bookmarks = response.data.response.bookmarks;
          this.itemLength = response.data.response.count;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
  },

  async created() {
    const userCookies = Cookies.get("user_data");
    if (userCookies) {
      const data = JSON.parse(userCookies);
      this.userID = data.user_id;
    }

    this.getBookmark();
  },

  watch: {
    page: {
      deep: true,
      handler() {
        this.getBookmark();
      },
    },

    sortBy: {
      deep: true,
      handler() {
        this.getBookmark();
      },
    },
  },
};
</script>

<style>
#producttype-image .v-skeleton-loader__image {
  height: 200px !important;
  width: 200px !important;
}

#skeleton-text .v-skeleton-loader__image {
  align-items: stretch !important;
}
</style>
