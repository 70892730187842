<template>
  <div class="d-flex justify-center align-center h-100 py-3">
    <v-card width="90%">
      <v-btn :to="{ name: 'home' }" class="ma-2" icon plain outlined color="white">
        <v-icon large color="black">mdi-arrow-left</v-icon>
      </v-btn>

      <v-row no-gutters align="center" justify="center">
        <v-col cols="auto" xl="8" lg="8" md="8" sm="8">
          <v-img @contextmenu.prevent class="mx-auto" src="@/assets/logo.svg" width="180" height="100"></v-img>
          <v-card-text class="text-white text-center">
            <p class="text-h6 text-center text-primary">
              Beli beragam jenis tiket cepat dan mudah
              <strong>hanya di e-tix</strong>
            </p>
          </v-card-text>

          <v-img @contextmenu.prevent class="mx-auto" src="@/assets/01_ilustration.svg" width="350"
            height="350"></v-img>
        </v-col>

        <v-col cols="auto" xl="4" lg="4" md="4" sm="4">
          <v-card-text>
            <p class="text-h5 text-primary font-weight-bold">
              Verifikasi Email
            </p>

            <p class="text-body-2 mb-2 text-grey">
              Kamu perlu memasukkan kode OTP yang telah kami kirimkan ke emailmu
            </p>

            <v-alert class="mb-2" v-model="success" type="success">
              Email berhasil terverifikasi, anda akan diarahkan ke halaman e-tix
              dalam {{ redirectCountdown }}
            </v-alert>

            <v-alert class="mb-2" type="error" v-model="error.show">
              {{ error.text }}
            </v-alert>
            <v-card>
              <v-card-text>
                <v-form ref="login" lazy-validation>
                  <v-otp-input @keydown.enter.prevent="verify" @change="error.show = false" :error="error.show"
                    :loading="loadingOTP" :disabled="success" v-model="otp" :autofocus="true" variant="solo-filled"
                    color="primary-container" type="number"></v-otp-input>
                  <v-btn :disabled="error.show || success || loadingOTP" :loading="verifying" @click="verify"
                    variant="tonal" color="primary" class="text-capitalize" block>
                    Verifikasi
                  </v-btn>
                </v-form>
              </v-card-text>

              <v-card-text v-if="resendOtpCountdown !== null" class="text-center">
                <span v-if="resendOtpCountdown > 0" class="text-caption font-weight-bold text-grey">Mengirim ulang OTP
                  dalam
                  <span class="text-red">{{ formattedTimerOtp }}</span>
                </span>

                <span v-else class="text-caption font-weight-bold text-grey">
                  Tidak menerima kode?
                  <span :style="{ cursor: 'pointer' }" @click="requestOTP()" class="text-primary">Kirim Ulang</span>
                </span>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </div>
  <footer-copyright />

  <v-snackbar snackbar color="white" v-model="snackbar.show" rounded="pill">
    <div class="text-black">
      {{ snackbar.text }}
    </div>

    <template v-slot:actions>
      <v-btn color="pink" rounded="pill" variant="text" @click="snackbar.status = false">
        Tutup
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";
import FooterCopyright from "@/components/FooterCopyright.vue";

export default {
  components: {
    FooterCopyright,
  },
  data() {
    return {
      snackbar: {
        show: false,
        text: "",
      },
      error: {
        show: false,
        text: "",
      },
      otp: "",
      loadingOTP: true,
      userID: null,
      credential: null,
      redirectCountdown: 5,
      resendOtpCountdown: null,
      success: false,
      verifying: false,
    };
  },
  created() {
    this.credential = this.$store.state.credential;

    if (isEmail(this.credential)) {
      this.axios
        .post("user/email", {
          user_email: this.credential,
        })
        .then((response) => {
          const userID = response.data.response.user_id;
          this.userID = userID;

          this.requestOTP();
        })
        .catch(() => {
          this.error.text = "Service sedang bermasalah";
          this.error.show = true;
        });
    } else if (isMobilePhone(this.credential)) {
      this.axios
        .post("user/phone", {
          phone_number: this.credential,
        })
        .then((response) => {
          const userID = response.data.response.user_id;
          this.userID = userID;

          this.requestOTP();
        })
        .catch(() => {
          this.error.text = "Service sedang bermasalah";
          this.error.show = true;
        });
    }
  },
  methods: {
    requestOTP() {
      this.loadingOTP = true;
      if (isEmail(this.credential)) {
        this.requestOTPEmail(this.userID, this.credential);
      } else if (isMobilePhone(this.credential)) {
        this.requestOTPPhone(this.userID, this.credential);
      }
    },
    requestOTPEmail(userID, email) {
      this.axios
        .post("user/otp/send", {
          user_id: userID,
          email_receiver: email,
        })
        .then(() => {
          this.loadingOTP = false;
          this.resendOtpTimer();
        })
        .catch(() => {
          this.error.text = "Service sedang bermasalah";
          this.error.show = true;
        });
    },
    requestOTPPhone(userID, phoneNumber) {
      this.axios
        .post("user/otp/send", {
          user_id: userID,
          phone_receiver: phoneNumber,
        })
        .then(() => {
          this.loadingOTP = false;
          this.resendOtpTimer();
        })
        .catch(() => {
          this.error.text = "Service sedang bermasalah";
          this.error.show = true;
        });
    },

    verify() {
      this.verifying = true;
      if (this.otp.length < 6) {
        this.error.text = "Masukan seluruh OTP";
        this.error.show = true;
      } else {
        this.axios
          .post("user/otp/verif", {
            user_id: this.userID,
            otp: this.otp,
          })
          .then((response) => {
            this.success = true;
            this.$store.dispatch("login", response.data.response)
            this.redirectTimer();
          })
          .catch((error) => {
            switch (error?.response?.status) {
              case 400: {
                this.error.text = "OTP yang kamu input salah";
                this.error.show = true;
                break;
              }

              default: {
                this.error.text = "Verifikasi gagal, terjadi masalah";
                this.error.show = true;
              }
            }
          });
      }
      this.verifying = false;
    },

    redirectTimer() {
      const interval = setInterval(() => {
        this.redirectCountdown -= 1;
        if (this.redirectCountdown == 0) {
          clearInterval(interval);
        }
      }, 1000);
    },

    resendOtpTimer() {
      this.resendOtpCountdown = 15 * 60 * 1000;
      const interval = setInterval(() => {
        this.resendOtpCountdown -= 1000;
        if (this.resendOtpCountdown <= 0) {
          clearInterval(interval);
        }
      }, 1000);
    },
  },
  computed: {
    formattedTimerOtp() {
      let time = this.resendOtpCountdown / 1000;
      let minutes = Math.floor(time / 60);
      let second = time - minutes * 60;
      const formattedTime = `${minutes} menit ${second} detik`;
      return formattedTime;
    },
  },
  watch: {
    redirectCountdown: {
      handler(value) {
        if (value == 0) {
          this.$router.push({ name: "login" });
        }
      },
    },
  },
};
</script>

<style scoped>
.blue {
  background-color: rgb(var(--v-theme-primary));
}

.rounded-br-xl {
  border-bottom-right-radius: 300px !important;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
</style>
