<template>
  <v-container>
    <v-row>
      <v-col cols="12" xxl="12" xl="12" lg="12" md="12" sm="12">
        <h1>Pesanan Saya</h1>
        <div>
          Halaman ini menampilkan pesananmu baik yang sudah kamu bayar maupun
          yang belum.
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xxl="7" xl="7" lg="7" md="7" sm="7"> </v-col>

      <v-col cols="12" xxl="5" xl="5" lg="5" md="5" sm="12">
        <v-row no-gutters>
          <v-col>
            <v-select
              v-model="sortBy.selected"
              density="compact"
              variant="outlined"
              color="primary"
              return-object
              :items="sortBy.data"
              item-title="title"
              item-value="value"
              label="Urut Berdasarkan"
              hide-details
              class="me-2"
            ></v-select>
          </v-col>

          <v-col>
            <v-select
              v-model="paymentStatus.selected"
              density="compact"
              hide-details
              variant="outlined"
              color="primary"
              :items="paymentStatus.data"
              item-title="title"
              return-object
              label="Status Pembayaran"
              class="mx-2"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="loading" align="center" justify="center">
      <v-col cols="12" xxl="9" xl="9" lg="9" md="9" sm="9">
        <v-card v-for="index in 3" :key="index" class="my-5" variant="outlined">
          <v-card-item>
            <div class="d-flex justify-end w-100 align-center">
              <div class="text-grey">Transaction ID:</div>
              <v-skeleton-loader
                class="bg-color"
                width="100"
                type="text"
              ></v-skeleton-loader>

              <div>
                <v-icon> mdi-chevron-down </v-icon>
              </div>
            </div>
          </v-card-item>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-else-if="!loading && transactions.length == 0"
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        xxl="9"
        xl="9"
        lg="9"
        md="9"
        sm="9"
        class="d-flex justify-center"
      >
        <v-sheet width="auto">
          <error
            :width="500"
            type="no_product"
            title="Maaf, Kami Tidak Menemukan Yang Kamu Cari"
            text="Silahkan ketik kata kunci lainnya."
          />
        </v-sheet>
      </v-col>
    </v-row>

    <v-row v-else align="center" justify="center">
      <v-col cols="12" xxl="9" xl="9" lg="9" md="9" sm="9">
        <template v-for="(transaction, index) in transactions" :key="index">
          <v-card class="my-5" variant="outlined">
            <v-card-item>
              <div class="d-flex justify-end w-100 align-center">
                <div class="text-grey me-2">
                  Transaction ID: {{ transaction.transaction_id }}
                </div>

                <div>
                  <v-icon @click="transaction.expand = !transaction.expand">
                    {{
                      transaction.expand ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}
                  </v-icon>
                </div>
              </div>
            </v-card-item>
            <v-expand-transition>
              <div v-show="transaction.expand">
                <template
                  v-for="(order, index_t) in transaction.order"
                  :key="index_t"
                >
                  <v-card variant="outlined" class="ma-3 mb-5">
                    <v-card-text class="mb-0 pb-0">
                      <div class="d-flex justify-end">
                        <div>Order ID : {{ order.order_id }}</div>
                      </div>
                    </v-card-text>
                    <v-card-text class="pb-0">
                      <template
                        v-for="(orderDetail, index_p) in order.order_details"
                        :key="index_p"
                      >
                        <div class="d-flex my-3 flex-wrap">
                          <v-img
                            cover
                            :src="
                              orderDetail.product.product_details[0]
                                .productdetail_image_url
                            "
                            width="auto"
                            height="auto"
                            max-width="100"
                            max-height="100"
                            class="align-self-start rounded-lg"
                          ></v-img>

                          <div class="me-auto ml-2">
                            <div>
                              {{ orderDetail.product.product_name }}
                            </div>
                            <div>
                              <v-icon>mdi-ticket-confirmation</v-icon>
                              {{ orderDetail.qty }} Tiket
                            </div>
                          </div>

                          <div class="align-self-end">
                            <v-icon
                              @click="orderDetail.expand = !orderDetail.expand"
                            >
                              {{
                                orderDetail.expand
                                  ? "mdi-chevron-up"
                                  : "mdi-chevron-down"
                              }}
                            </v-icon>
                          </div>
                        </div>

                        <v-expand-transition>
                          <div class="mb-4" v-show="orderDetail.expand">
                            <v-divider :thickness="3"></v-divider>
                            <template
                              v-for="(
                                productType, index_pt
                              ) in orderDetail.product_types"
                              :key="index_pt"
                            >
                              <div class="my-2 d-flex">
                                <div>
                                  <v-sheet
                                    color="transparent"
                                    class="d-none d-md-flex me-2"
                                    width="100"
                                  >
                                  </v-sheet>
                                </div>
                                <div class="me-auto ml-2">
                                  <div>{{ productType.producttype_name }}</div>
                                  <div>
                                    <v-icon>mdi-ticket-confirmation</v-icon>
                                    {{ productType.qty }}
                                    Tiket
                                  </div>
                                </div>

                                <div>
                                  {{ numberToRupiah(productType.amount) }}
                                </div>
                              </div>
                            </template>
                          </div>
                        </v-expand-transition>
                      </template>
                    </v-card-text>
                  </v-card>
                </template>
              </div>
            </v-expand-transition>

            <v-divider :thickness="3"></v-divider>
            <v-card-text>
              <div class="d-flex justify-space-between align-center flex-wrap">
                <div>
                  <v-chip
                    v-if="transaction.transaction_status == 0"
                    label
                    color="warning"
                  >
                    Menunggu Pembayaran
                  </v-chip>
                  <v-chip
                    v-if="transaction.transaction_status == 1"
                    label
                    color="success"
                  >
                    Pembayaran Berhasil
                  </v-chip>
                  <v-chip
                    v-if="transaction.transaction_status == 2"
                    label
                    color="error"
                  >
                    Pembayaran Gagal
                  </v-chip>
                </div>

                <div class="text-body-1 font-weight-bold">
                  Total :
                  <span v-if="transaction.payment" class="text-orange">{{
                    numberToRupiah(parseInt(transaction.payment.payment_total))
                  }}</span>
                  <span class="text-orange" v-else>{{
                    numberToRupiah(transaction.transaction_total)
                  }}</span>
                </div>
              </div>
            </v-card-text>

            <v-divider :thickness="3"></v-divider>

            <v-card-actions>
              <v-btn
                class="text-none"
                variant="tonal"
                color="primary"
                v-if="transaction.transaction_status == 1"
              >
                Lihat E-Ticket
                <my-order-ticket
                  :tickets="transaction.tickets"
                  :product-type-name="false"
                ></my-order-ticket>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                class="text-none"
                color="primary"
                :to="{
                  name: 'order-detail',
                  params: { id: transaction.transaction_id },
                }"
                >Lihat Detail</v-btn
              >
              <v-btn
                v-if="transaction.transaction_status == 0"
                class="text-none bg-primary"
                variant="tonal"
                @click="openPayment(transaction.payment.payment_link)"
                >Bayar</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
      </v-col>

      <v-col cols="12" xxl="9" xl="9" lg="9" md="9" sm="9">
        <v-pagination
          :disabled="loading"
          v-model="this.page"
          :length="lastPage"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Cookies from "js-cookie";
import { numberToRupiah } from "@/utils/numberFormatter";
import Error from "@/components/error/ErrorComponent.vue";
import MyOrderTicket from "./MyOrderTicket.vue";

const sortBySelection = [
  {
    title: "Baru Dibeli",
    value: "date",
    order: "desc",
  },
];

const paymentStatusSelection = [
  {
    title: "Semua",
    value: null,
  },
  {
    title: "Berhasil",
    value: "success",
  },
  {
    title: "Menunggu pembayaran",
    value: "pending",
  },
  {
    title: "Gagal",
    value: "failed",
  },
];

export default {
  components: {
    MyOrderTicket,
    Error,
  },

  data() {
    return {
      transactions: [],
      categories: [
        {
          productcategory_name: "Semua Kategori",
          productcategory_id: null,
        },
      ],
      email: null,
      userID: null,
      page: 1,
      itemsPerPage: 10,
      itemLength: 100,
      sortBy: {
        data: sortBySelection,
        selected: sortBySelection[0],
      },
      paymentStatus: {
        data: paymentStatusSelection,
        selected: paymentStatusSelection[0],
      },
      loading: true,
    };
  },

  async created() {
    const userCookies = Cookies.get("user_data");
    if (userCookies) {
      const data = JSON.parse(userCookies);
      this.userID = data.user_id;
    }

    this.getOrder();
  },

  computed: {
    lastPage() {
      return Math.ceil(this.itemLength / this.itemsPerPage);
    },
  },

  methods: {
    openPayment(paymentUrl) {
      const windowFeature =
        "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=500,height=700";
      window.open(paymentUrl, "targetWindow", windowFeature);
    },
    async getOrder() {
      this.loading = true;

      await this.axios
        .get(`product/transactions/user/${this.userID}`, {
          params: {
            page: this.page,
            pageSize: this.itemsPerPage,
            sortBy: this.sortBy.selected.value,
            oder: this.sortBy.selected.order,
            orderStatus: this.paymentStatus.selected.value,
          },
        })
        .then((response) => {
          this.transactions = response.data.response.transaction.map(
            (transaction) => {
              transaction.order = transaction.order.map((order) => {
                const newOrderDetail = [];

                for (const orderDetail of order.order_details) {
                  const findOrderDetail = newOrderDetail.find((element) => {
                    return (
                      element.product.product_id ==
                      orderDetail.product.product_id
                    );
                  });

                  transaction.tickets.forEach((ticket) => {
                    if (ticket.orderdetail_id === orderDetail.orderdetail_id) {
                      ticket.producttype_name =
                        orderDetail.product.producttype_name;
                    }
                  });

                  if (findOrderDetail) {
                    findOrderDetail.product_types.push({
                      producttype_name: orderDetail.product.producttype_name,
                      qty: orderDetail.qty,
                      amount: orderDetail.amount,
                    });

                    findOrderDetail.qty += orderDetail.qty;
                  } else {
                    orderDetail.product_types = [
                      {
                        producttype_name: orderDetail.product.producttype_name,
                        qty: orderDetail.qty,
                        amount: orderDetail.amount,
                      },
                    ];
                    newOrderDetail.push(orderDetail);
                  }
                }

                order.order_details = newOrderDetail;
                return order;
              });

              return transaction;
            }
          );

          this.loading = false;
          this.itemLength = response.data.response.count;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    numberToRupiah(number) {
      return numberToRupiah(number);
    },
  },

  watch: {
    page: {
      deep: true,
      handler() {
        this.getOrder();
      },
    },
    "paymentStatus.selected": {
      deep: true,
      handler() {
        this.getOrder();
      },
    },
    "sortBy.selected": {
      deep: true,
      handler() {
        this.getOrder();
      },
    },
  },
};
</script>

<style></style>
