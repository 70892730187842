<template>
  <div id="setting" :style="{ 'min-height': '80vh' }">
    <navigation-account></navigation-account>
    <router-view />
  </div>
</template>

<script>
import NavigationAccount from "./NavigationAccount.vue";

export default {
  components: { NavigationAccount },
};
</script>

<style></style>