<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Preferensi</h1>
        <div class="text-caption">Pilih hal-hal yang kamu sukai.</div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card variant="outlined">
          <v-card-item>
            <v-card-title> Hiburan </v-card-title>
          </v-card-item>

          <v-card-text>
            <v-chip
              v-for="(value, index) in hiburan"
              :key="index"
              class="me-15 my-10"
              >{{ value }}</v-chip
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card variant="outlined">
          <v-card-item>
            <v-card-title> Transportasi </v-card-title>
          </v-card-item>

          <v-card-text>
            <v-chip
              v-for="(value, index) in transportasi"
              :key="index"
              class="me-15 my-10"
              >{{ value }}</v-chip
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card variant="outlined">
          <v-card-item>
            <v-card-title> Pengembangan Diri </v-card-title>
          </v-card-item>

          <v-card-text>
            <v-chip
              v-for="(value, index) in pengembangan_diri"
              :key="index"
              class="me-15 my-10"
              >{{ value }}</v-chip
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      hiburan: [
        "Taman Hiburan",
        "Water Parks",
        "Museum",
        "Kebun Binatang",
        "Alam",
        "Tempat Bersejarah",
        "Makanan",
        "Permainan",
        "Karaoke",
        "Konser",
        "Komedi",
        "Olahraga",
      ],
      transportasi: ["Kereta", "Pesawat Terbang", "Bus", "Kapal Laut"],
      pengembangan_diri: ["Workshop", "Webinar/Seminar"],
    };
  },
};
</script>

<style>
</style>