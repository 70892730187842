<template>
  <v-dialog activator="parent">
    <template #default="{ isActive }">
      <v-row align="start">
        <v-spacer></v-spacer>
        <v-col cols="12" xxl="6" xl="6" lg="6" md="6" sm="10">
          <v-card>
            <v-card-text>
              <div class="d-flex justify-end">
                <v-icon @click="isActive.value = false">mdi-close</v-icon>
              </div>
            </v-card-text>
            <v-window v-model="window" :show-arrows="true">
              <v-window-item
                v-for="(ticket, index) in tickets"
                :value="index"
                :key="index"
              >
                <v-card-text class="mx-5 mb-3">
                  <v-card @vue:mounted="getQR(ticket)" variant="outlined">
                    <v-card-item>
                      <div class="d-flex justify-space-between flex-wrap">
                        <div class="font-weight-bold my-1">
                          {{
                            productTypeName
                              ? productTypeName
                              : ticket.producttype_name
                          }}
                        </div>

                        <div class="my-1">
                          Nomor Tiket : {{ ticket.ticket_number }}
                        </div>
                      </div>
                    </v-card-item>

                    <v-divider></v-divider>

                    <v-card-text>
                      <div class="d-flex flex-wrap">
                        <div>
                          <v-img
                            v-if="ticket.qr"
                            width="auto"
                            height="auto"
                            :src="'data:image/png;base64, ' + ticket.qr"
                          >
                          </v-img>
                          <v-skeleton-loader
                            v-else
                            type="image"
                            width="200"
                            height="200"
                            class="ma-2"
                          ></v-skeleton-loader>
                        </div>

                        <div>
                          <div class="mb-3">
                            <div class="font-weight-bold">Status Ticket</div>
                            <div>
                              <v-chip
                                label
                                v-if="ticket.ticket_is_used"
                                color="success"
                              >
                                Sudah digunakan
                              </v-chip>

                              <v-chip
                                label
                                v-if="!ticket.ticket_is_used"
                                color="primary"
                              >
                                Belum digunakan
                              </v-chip>
                            </div>
                          </div>

                          <div>
                            <div class="font-weight-bold">Masa Berlaku</div>
                            <div>Berlaku di tanggal terpilih</div>
                          </div>
                        </div>
                      </div>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        @click="exportToPDF(ticket)"
                        :disabled="!ticket.qr"
                        class="text-none"
                        variant="flat"
                        color="primary"
                      >
                        <template #prepend>
                          <v-icon>mdi-tray-arrow-down</v-icon>
                        </template>
                        Unduh E-Ticket
                      </v-btn>
                    </v-card-actions>
                  </v-card>

                  <div
                    :style="{
                      position: 'fixed',
                      width: '400px',
                      visibility: 'hidden',
                    }"
                  >
                    <v-card :id="ticket.ticket_number" variant="outlined">
                      <v-card-item>
                        <div class="d-flex justify-space-between">
                          <div class="font-weight-bold">
                            {{
                              productTypeName
                                ? productTypeName
                                : ticket.producttype_name
                            }}
                          </div>

                          <div>Nomor Tiket : {{ ticket.ticket_number }}</div>
                        </div>
                      </v-card-item>

                      <v-divider></v-divider>

                      <v-card-text>
                        <img
                          v-if="ticket.qr"
                          width="100%"
                          height="auto"
                          :src="'data:image/png;base64, ' + ticket.qr"
                        />
                      </v-card-text>
                    </v-card>
                  </div>
                </v-card-text>
              </v-window-item>
            </v-window>
          </v-card>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </template>
  </v-dialog>
</template>

<script>
import { createPdf } from "@/utils/pdf";
export default {
  props: ["tickets", "productTypeName"],
  data() {
    return {
      window: 0,
    };
  },
  methods: {
    getQR(ticket) {
      this.axios
        .get(`ticket/qr/${ticket.ticket_number}/get`)
        .then((response) => {
          ticket.qr = response.data.response.qr_code;
        });
    },

    async exportToPDF(ticket) {
      const ticketNumber = ticket.ticket_number;

      const element = document.getElementById(ticketNumber);

      createPdf(element).save(`${ticketNumber}.pdf`);
    },
  },
};
</script>

<style lang="scss" scoped></style>
